import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAsUmHxqP_vQKDTXlzrJdAYu2QFgjMXgrM',
  authDomain: 'gps-tracker-3c2a1.firebaseapp.com',
  databaseURL: 'https://gps-tracker-3c2a1.firebaseio.com',
  projectId: 'gps-tracker-3c2a1',
  storageBucket: 'gps-tracker-3c2a1.appspot.com',
  messagingSenderId: '893074004983',
  appId: '1:893074004983:web:f27abdb4faaa62d395acf2'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = firebase.messaging()

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

Notification.requestPermission().then((permission) => {
  if (permission === 'granted') {
    console.log('Notification permission granted.')

    messaging.getToken({ vapidKey: 'BCevw7KGPM_A_DMtPQXagCokXyF7D8uEihJs5Shq0bY6MXwE6PQxXtPFnAxvsnKapOQDN45EwdQ2PadKIcLyM0w' }).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        console.log(currentToken)
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.')
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // ...
    })
  }
}).catch((err) => {
  console.log('Unable to get permission to notify.', err)
})

// Menangani penerimaan pesan push
messaging.onMessage((payload) => {
  console.log('Pesan push diterima:', payload)
  // Tambahkan logika untuk menangani pesan push di sini
})

createApp(App).use(store).use(router).mount('#app')
